._720kb-datepicker-calendar-header:nth-child(odd) {
  background: map-get($color-map, secondary);
}

._720kb-datepicker-calendar-header:nth-child(even) {
  background: map-get($color-map, terziary);
}

._720kb-datepicker-calendar-days-header {
  background: rgba(map-get($color-map, secondary), 0.08);
}