.tag {
  padding: 3px 4px 2px;
  border-radius: 3px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  line-height: 1;

  &--dark {
    background: map_get($color-map, primary);
    color: map_get($color-map, white);
  }

  &--light {
    background: map_get($color-map, gallery);
    color: map_get($color-map, primary);
  }

  &--danger {
    background: map_get($color-map, carnation);
    color: map_get($color-map, white);
  }

  &--warning {
    background: map_get($color-map, buttercup);
    color: map_get($color-map, primary);
  }

  &--success {
    background: map_get($color-map, apple);
    color: map_get($color-map, white);
  }

  &--primary {
    background: map_get($color-map, primary);
    color: map_get($color-map, white);
  }

  &--secondary {
    background: map_get($color-map, secondary);
    color: map_get($color-map, white);
  }

  &--terziary {
    background: map_get($color-map, terziary);
    color: map_get($color-map, white);
  }

  // dentro alla collection
  @include when-inside ("collection", "__figure") {
    position: absolute;
    right: 15px;
    top: 5px;
    padding-right: 1px;
    padding-left: 15px;

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      background: #ececec;
      position: absolute;
      left: 4px;
      transform: translateY(-50%);
      top: 50%;
      border-radius: 100%;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10.3px;
      border-color: transparent transparent transparent #000000;
      position: absolute;
      left: 100%;
      top: 0;
      margin-left: -1px;
    }
  }
  
}