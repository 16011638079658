body {
  @extend %size-base;
  font-family: 'Avenir-Medium';
}

.style-alpha,
%style-alpha {
  @extend %size-huge;
  font-family: 'Avenir-Black';
  text-transform: uppercase;
}

.style-bravo,
%style-bravo {
  @extend %size-medium;
  font-family: 'Avenir-Medium';
  color: map-get($map: $color-map, $key: secondary);
}

.style-charlie,
%style-charlie {
  @extend %size-large;
  font-family: 'Avenir-Black';
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    max-width: 80px;
    height: 6px;
    background-color: map-get($map: $color-map, $key: terziary);
    margin-top: 10px;
  }
}

.style-delta,
%style-delta {
  @extend %size-huge;
  font-family: 'Avenir-Black';
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    max-width: 80px;
    height: 6px;
    background-color: map-get($map: $color-map, $key: terziary);
    margin-top: 10px;
  }
}

.style-echo,
%style-echo {
  @extend %size-medium-large;
  font-family: 'Avenir-Medium';
}

.style-foxtrot,
%style-foxtrot {
  @extend %size-big;
  font-family: 'Avenir-Black';
}

.style-golf,
%style-golf {
  @extend %size-medium;
  font-family: 'Avenir-Book';
}

.style-hotel,
%style-hotel {
  @extend %size-small;
  font-family: 'Avenir-Black';
  color: map-get($color-map, secondary);
}

.style-india,
%style-india {
  @extend %size-medium-large;
  font-family: 'Avenir-Black';
  text-transform: uppercase;
}

.style-juliett,
%style-juliett {
  @extend %size-medium-large;
  font-family: 'Avenir-Book';
}



p {
  margin: 1.5rem 0;

  &:last-child {
    margin: 0;
  }
}

ul li {
  margin: 1.5rem 0;
}

blockquote {
  @extend %size-large;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;

  &:before,
  &:after {
    font-size: 250%;
    line-height: 0.1em;
    vertical-align: -0.4em;
  }

  &:before {
    content: open-quote;
    margin-right: 2.5rem;
  }

  &:after {
    content: close-quote;
    margin-left: 0;
  }
}

.date {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;

  &--boxed {
    width: 81px;
    height: 81px;
    box-shadow: 0 1px 5px 0 rgba(124, 132, 149, 0.5);
    background-color: #ffffff;
    justify-content: center;
  }

  &__month {
    @extend %size-normal;
    font-family: 'Avenir-Light';
    letter-spacing: 2.57px;
    line-height: 0.9;
    margin-bottom: 10px;
  }

  &__day {
    font-size: 3.6rem;
    font-family: 'Avenir-Book';
    letter-spacing: 3px;
    line-height: 0.8;
  }
}

// todo
.text {

  // Author
  &--author {
    strong:after {
      content: ' - ';
    }
  }

  &--price { 
    display: flex;
    flex-direction: column;

    & > * {
      line-height: 1.2;
    }
  }

}