.header {

  // header—-alpha    :: White senza border bottom NO FIXED
  // header—-bravo    :: Gradiente viola fixed
  // header—-charlie  :: Gradiente pink fixed
  // header--delta    :: Grey header minimal NO FIXED

  $topbar-root: &;

  width: 100%;
  padding: 0;
  transition: background 250ms;

  

  &--alpha {
    background: map-get($color-map, white);
  }

  &--bravo,
  &--charlie {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  &--delta {
    background: map-get($color-map, pale-grey);
  }

  &__main {
    
    // BRAVO
    #{$topbar-root}--bravo & {
      background: linear-gradient(to bottom, #38102f, rgba(56, 16, 47, 0));
      min-height: 140px;

      @include respond-to("s-768") {
        background: linear-gradient(to bottom, #38102f, rgba(56, 16, 47, 0.35) 52%, rgba(248, 248, 248, 0));
        min-height: 240px;
      }
    }

    // CHARLIE
    #{$topbar-root}--charlie & {
      background: linear-gradient(to bottom, #ffc0cb, rgba(255, 192, 203, 0));
      min-height: 140px;

      @include respond-to("s-768") {
        background: linear-gradient(to bottom, #ffc0cb, rgba(255, 192, 203, 0.35) 52%, rgba(255, 192, 203, 0));
        min-height: 240px;
      }
    }
    
  }

  // Contenitore interno
  &__inner {
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    position: relative;

    @include respond-to("s-768") {
      padding: 2.5rem 0;
      flex-direction: row;
      align-items: center;
    }
  }

  // barra banner
  // &__banner {
  //   @extend %size-small;
  //   padding: 1rem 0;
  //   background: map-get($color-map, alabaster);
  //   display: none;

  //   @include respond-to("s-768") {
  //     display: block;
  //   }
  // }

  &__callme {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 5px;
      width: 14px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    flex-shrink: 0;
    width: 50px;
    order: 2;

    @include respond-to("s-768") {
      width: 80px;
      order: 1;
    }

    #{$topbar-root}__topbar & {
      width: 50px;
    } 
  }

  // barra top fixed
  &__topbar {
    position: fixed;
    padding: 0;
    left: 0;
    right: 0;
    top: -50px;
    transition: top 400ms, box-shadow 200ms 200ms;
    background: rgba(map-get($color-map, white), .95);
    z-index: 9999;
    
    a:link,
    a:visited {
      color: map-get($color-map, primary);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    }

    &--visible {
      top: 0;
      box-shadow: 0 0.8rem 2rem -0.9rem rgba(0, 0, 0, 0.3);
    }

    // Se l'header è di tipo sticky -> la topbar non deve esistere
    // #{$topbar-root}--sticky & {
    //   display: none;
    // }
  }

  &__topbarInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__navigation {
    list-style: none;
    display: flex;
    width: 100%;
    align-items: center;
    order: 3;
    justify-content: flex-end;

    @include respond-to("s-992") {
      width: auto;
      justify-content: flex-start;
    }
  }

  &__navigationItem {
    text-transform: uppercase;
    margin: 0;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    .navigation__anchor {
      display: flex;
      padding: 0.5rem;
      cursor: pointer;
      color: white;

      #{$topbar-root}--alpha & {
        color: map-get($map: $color-map, $key: terziary);
      }

      #{$topbar-root}--delta & {
        color: map-get($map: $color-map, $key: primary);
      }

    }
  }
}

