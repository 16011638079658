.slice {
    $root: &;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &--landscape {
      max-width: map-get($breakpoints, 's-1500');      

      & [class*="col-"] {
        position: static;
      }
  
      & .col-sm-12:first-child {
        min-height: 300px;
  
        @include respond-to("s-768") {
          min-height: auto;
        }
      }
  
      & .col-sm-12:last-child {
        margin-top: -50px;
        
        @include respond-to("s-768") {
          margin-top: 0;
        }
      }
      
    }

    &--portrait {
      display: flex;
      align-items: center;
    }      
  
    // altezza schermo
    &--hero {
      height: 100vh;
    }

    // spaziatura verticale
    &--gutter {
      &-xs {padding: 2rem 0;}
      &-s {padding: 5rem 0;}
      &-m {padding: 10rem 0;}
      &-l {padding: 15rem 0;}
      &-xl {padding: 18rem 0;}
      &-xxl {padding: 30rem 0;}
    }

    &--size {
      @include respond-to("s-768") {
        &-xxl {
          min-height: 60rem;
        }
      }
    }

    &__media,
    &__filter {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    // Contenuto media (immagini - video)
    &__media {
      z-index: 1;

    // dentro la portrait l'immagine si allinea in verticale
    #{$root}--portrait & {
      display: flex;
      align-items: center;
      position: static;
      height: 100%;
      justify-content: center;
    }

      // media dentro una slice fixed
      #{$root}--fixed & {
        // fixed non supportato dai device mobile
        @at-root .no-touch & {
          background-attachment: fixed;
        }

        background-size: cover;
        background-position: center center;    
      }

      #{$root}--blurred & {
        @include respond-to("s-768") {
          filter: blur(5px);  
        }
      }

      // Landscapee
      #{$root}--landscape & {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;

        @include respond-to("s-768") {
          height: 100%;
          background-size: cover;
        }
      }

      #{$root}--landscape-toleft & {
        @include respond-to("s-768") {
          background-size: contain;
          background-position: right center;
          right: 0;
          left: auto;
          right: 50%;
        }
      }

      #{$root}--landscape-toright & {
        background-size: contain;
        @include respond-to("s-768") {
          background-position: left center;   
          left: 50%;
        }
      }

      // media dentro una slice video
      video,
      iframe {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__mediaInner {
      overflow: hidden;
      max-width: 100%;
    }
    
    // immagine nella slice portrait
    &__image {
      display: block;
      max-width: 100%;
      
      @include respond-to("s-768") {
        &--s {
          max-height: 200px;
        }
        &--m {
          max-height: 300px;
        }
        &--l {
          max-height: 400px;
        }
        &--xl {
          max-height: 600px;
        }
        &--xxl {
          max-height: 900px;
        }
      }
    }
    
    // filtro colorato
    &__filter {
      z-index: 2;

      #{$root}--gradient & {
        background-image: linear-gradient(to bottom,rgba(map-get($color-map, primary), 0.6),rgba(map-get($color-map, primary), 0));
        background-color: transparent;
      }

      #{$root}--dark & {
        background: rgba(0,0,0, .2);
      }
    }

    // contenuti testuali
    &__text {
      position: relative;
      z-index: 3;
      margin: 15px auto;

      #{$root}--portrait & {
        display: flex;
        align-items: center;
        height: 100%;
      }
  
      #{$root}--landscape & {
        background: #fff;
        padding: 1.6rem;

        @include respond-to("s-768") {
          margin: -15px auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      #{$root}--landscape-toleft & {
        
      } 

      #{$root}--landscape-toright & {
        
      } 
        
      #{$root}--hero & {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 30px);
        text-align: center;

        @include respond-to("s-768") {
            width: calc(70% - 30px);
        }
      }

    }
}