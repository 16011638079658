.card {
  // common rules
  $duration: 0.3s;
  $root: &;
  flex-basis: 100%;
  background-color: map-get($color-map, white);
  position: relative;
  overflow:hidden;
  transition: all $duration;
  display: flex;
  flex-direction: column;
  box-shadow: none;

  // Se mi trovo in un carosel slick--showcase
  @at-root .slick--showcase & {
    display: flex!important;
  }

  // variante alpha
  &--alpha {
    box-shadow: 0 1px 5px 0 rgba(57, 15, 47, 0.09);

    &:hover {
      box-shadow: 0 7px 30px 0 rgba(102, 67, 90, 0.42);
    }
  }

  // variante DELTA
  &--delta {
    &:hover {
      box-shadow: 0 7px 30px 0 rgba(102, 67, 90, 0.42); // dovrebbe essere diversa
    }
  }

  // BRAVO
  &--bravo {
    border: 1px solid map-get($color-map, gray);
    flex-direction: row;
  }

  // HOTEL
  &--hotel {
    border-radius: 4px;
    border: 1px solid map-get($color-map, blue-grey);
    padding: 1.6rem;
  }

  // GOLF
  &--golf {
    box-shadow: 0 0 3px 0 rgba(38, 38, 38, 0.5);
  }

  // card charlie
  &--charlie {
    background-color: transparent; // il colore di sfondo viene dato in questo caso dal body
  }

  // le card vengono sempre innestate in una grid che di default stretcha gli item (card)
  // Con la classe card--fit, la card prende solo lo spazio dei contenuti
  &--fit {
    align-self: flex-start;
  }

  // configurazione che disattiva i link presenti in una card
  &--noLinked {
    pointer-events: none;
  }

  // Parte alta
  &__header{
    overflow:hidden;
    background-color: map-get($color-map, primary);
    transition: height $duration;
    position: relative;

    #{$root}--hotel & {
      background-color: transparent;
    }

    // card BRAVO
    #{$root}--bravo & {
      flex-basis: 30%;
      display: flex;
    }

    // card FOXTROT
    #{$root}--foxtrot & {
      overflow: visible;
    }

    // card charlie
    #{$root}--charlie & {
      border: 1px solid map-get($map: $color-map, $key: gallery);
      border-bottom: 0;
    }

    #{$root}--golf & {
      background-color: map-get($color-map, white);
    }

    a {
      height: inherit; 
      display: block;

      #{$root}--bravo & {
        width: 100%
      }
    }
  }

  &__actions {
    position: absolute;
    bottom: -21px;
    display: flex;
    right: 10px;
    z-index: 10;
  }

  &__action {
    margin-left: 1rem;

    &--added {
      svg {
        fill: map-get($color-map, error);
      }
    }
  }

  // Contenuto media (immagine / background)
  &__thumb {
    display:block;
    opacity: 1;
    transform: scale(1);
    transition: opacity $duration, transform $duration;

    #{$root}--bravo & {
      height: 100%
    }

    // versione BG 
    #{$root}--bg & {
      padding-bottom: 70%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    } 

    // versione IMG spaced
    #{$root}--golf & {
      display: block;
      max-width: 100%;
      max-height: 250px;
      margin: 0 auto;
      padding: 15px;
    }

    // versione IMG
    #{$root}--img & {
      display: block;
      max-width: 100%;
    }

    #{$root}--bravo:hover &,
    #{$root}--charlie:hover & {
      opacity: 0.6;
      transform: scale(1.2);
    }
  }

  // call to action
  &__cta {    
    position: absolute;
    opacity:0;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all $duration;
    backface-visibility: none;
    
    #{$root}:hover & {
      top: 50%;
      opacity: 1;
    }

    // Se mi trovo in una card echo
    #{$root}--echo & {
      display: none;
    }
  }

  &__text {

    #{$root}--golf & {
      & > *:not(:first-child) {
        margin-top: 1rem;
      }
    }

    #{$root}--hotel & {
      & > * {
        margin-top: 1.6rem;
      }
    }

  }

  &__body {
    position: relative;
    padding: 1.6rem;
    box-shadow: none;
    transition: box-shadow 200ms;
    flex: 1; // prende in altezza tutto lo spazio a disposizione cosi che nelle collection sono tutte alte uguali
    
    @include respond-to("s-768") {
      padding: 3rem 2rem;
    }

    // CARD ALPHA 
    #{$root}--alpha & {
      border-top: 1px solid map-get($color-map, lower-grey);
    }

    // ALPHA HOVER
    #{$root}--alpha:hover & {
      box-shadow: inset 0px -10px 0px 0px map-get($color-map, terziary)
    }

    // CARD Foxtrot 
    #{$root}--foxtrot & {
      border: 1px solid rgba(map-get($color-map, primary), .30);

      @include respond-to("s-768") {
        padding: 2rem;
      }
    }

    // BRAVO
    #{$root}--bravo & {
      flex-basis: 70%;
    }

    // GOLF
    #{$root}--golf & {
      @include respond-to("s-768") {
        padding: 0 1.6rem 1.6rem;
      }
    }

    // HOTEL
    #{$root}--hotel & {
      padding: 0;
    }

    // CHARLIE
    #{$root}--charlie & {
      background-color: map-get($color-map, white);
      border: 1px solid map-get($map: $color-map, $key: gallery);
      border-top: 0;
      left: 15px;
      width: calc(100% - 30px);
      top: 0px;
      flex: 1; // per comprire tutta l'altezza della card

      &:before,
      &:after {
        content: '';
        top: 0;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:before {
        left: -15px;
        border-width: 0 15px 10px 0;
        border-color: transparent #000000 transparent transparent;
      }

      &:after {
        right: -15px;
        border-width: 10px 15px 0px 0;
        border-color: #000000 transparent  transparent transparent;
      }

    }

    // DELTA
    #{$root}--delta & {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(map-get($color-map, primary), 0), map-get($color-map, primary));
    }

    // DELTA HOVER
    #{$root}--delta:hover & {
      box-shadow: inset 0px -14px 0px 0px map-get($color-map, terziary)
    }

    // ECHO
    #{$root}--echo & {
      background-color: rgba($color: map-get($color-map, white), $alpha: .8);
      position: absolute;
      bottom: -100%;
      left: 0;
      right: 0;
      max-height: 60%;
      transition: bottom 350ms cubic-bezier(0.7, 0, 0.3, 1);
      
      &:before {
        top: -10px;
        background-color: rgba($color: map-get($color-map, white), $alpha: .8);
      }
    }

    #{$root}--echo:hover & {
      bottom: 0;
    }

  }

  // ANCHOR ELEMENT TEXT WRAP
  &__anchor {

    #{$root}__body & {
      display: flex;
      align-items: center;
    }

  }

  // EXTRA CONTENT INSIDE BODY
  &__extra {
    margin-right: 1.5rem;

    #{$root}--delta & {
      color: map-get($color-map, white);
    }

  }
  
  // BODY TITLE 
  &__title { 

    #{$root}--alpha & {
      @extend %size-normal;
      font-family: 'Avenir-Black';
      color: map-get($color-map, dark-gray);
    }

    #{$root}--delta & {
      @extend %style-echo;
      color: map-get($color-map, white);
    }

    #{$root}--foxtrot & {
      @extend %style-echo;
      color: map-get($color-map, terziary);
    }

    #{$root}--golf & {
      @extend %size-medium;
      font-family: 'Avenir-Black';
      text-transform: uppercase;
    }

    #{$root}--hotel & {
      display: none; // il titolo è nell'header
    }
    
  }
  
  // BODY DESCRIPTION
  &__description {
    margin: 0; 
    @extend %size-small;

    #{$root}--alpha & {
      color: map-get($color-map, dark-gray);
    }

    #{$root}--delta & {
      color: map-get($color-map, white);
    }

    #{$root}--foxtrot & {
      color: map-get($color-map, primary);
    }

  }
  
  &__footer{
    display: none;
    padding: 1rem 2rem;
    color: map-get($color-map, white);
    background: map-get($color-map, secondary);
  }
}