@font-face {
  font-family: 'Avenir-Black';
  src: url('/assets/fonts/Avenir-Black.eot');
  src: url('/assets/fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Avenir-Black.svg#Avenir-Black') format('svg'),
       url('/assets/fonts/Avenir-Black.ttf') format('truetype'),
       url('/assets/fonts/Avenir-Black.woff') format('woff'),
       url('/assets/fonts/Avenir-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('/assets/fonts/Avenir-Medium.eot');
  src: url('/assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Avenir-Medium.svg#Avenir-Medium') format('svg'),
       url('/assets/fonts/Avenir-Medium.ttf') format('truetype'),
       url('/assets/fonts/Avenir-Medium.woff') format('woff'),
       url('/assets/fonts/Avenir-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('/assets/fonts/Avenir-Book.eot');
  src: url('/assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Avenir-Book.svg#Avenir-Book') format('svg'),
       url('/assets/fonts/Avenir-Book.ttf') format('truetype'),
       url('/assets/fonts/Avenir-Book.woff') format('woff'),
       url('/assets/fonts/Avenir-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('/assets/fonts/Avenir-Light.eot');
  src: url('/assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Avenir-Light.svg#Avenir-Light') format('svg'),
       url('/assets/fonts/Avenir-Light.ttf') format('truetype'),
       url('/assets/fonts/Avenir-Light.woff') format('woff'),
       url('/assets/fonts/Avenir-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$typeSetting: (
  'huge': (
    'default': (
      'font-size': '2.4rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '4rem'
    )
  ),
  'big': (
    'default': (
      'font-size': '2rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '3rem'
    )
  ),
  'large': (
    'default': (
      'font-size': '2.6rem',
      'line-height': '1'
    )
  ),
  'medium-large': (
    'default': (
      'font-size': '2rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '2.4rem'
    )
    
  ),
  'medium': (
    'default': (
      'font-size': '2rem',
      'line-height':'2.4rem'
    )
  ),
  'normal': ( 
    'default': (
      'font-size': '1.8rem',
      'line-height':'2.6rem'
    )
  ),
  'base': (
    'default': (
      'font-size': '1.6rem',
      'line-height':'2.6rem'
    )
  ),
  'small': (
    'default': (
      'font-size': '1.4rem',
      'line-height':'1.8rem'
    )
  ),
  'micro': (
    'default': (
      'font-size': '1.2rem'
    )
  )
);