.navigation {
  $navigation-root: &;

  width: 100%;
  padding: 0 1.6rem;
  max-width: map-get($breakpoints, s-1200 );

  @include respond-to('s-992') {
    margin-top: 0;
    order: 2
  }

  &__wrap {
    list-style: none;
    position: fixed;
    top: 0;
    left: -100%;
    width: calc(100% - 60px);
    height: 100vh;
    
    z-index: 999;
    box-shadow: 0 0px 8px 1px rgba(0,0,0,0.5);

    transition: opacity 200ms, left 200ms;
    transform: none;

    &--firstLevel {
      background: linear-gradient(to bottom, map-get($map: $color-map, $key: secondary) 0%,darken(map-get($map: $color-map, $key: secondary), 10%) 100%);
      overflow: auto;

      &#{$navigation-root}__wrap--visible {
        left: 0;
      }
      
      @include respond-to('s-992') {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    

    @include respond-to('s-992') {
      position: static;
      margin: 0;
      opacity: 1;
      height: auto;
      box-shadow: none;
      width: 100%;
      background: none;
      overflow: visible;
    }

    // Sottolivelli
    &--secondLevel,
    &--thirdLevel {
      width: 100%;
      opacity: 0;
      height: 0;
      overflow: hidden;
      position: static;
      box-shadow: none;

      &#{$navigation-root}__wrap--visible {
        opacity: 1;
        height: auto;
        overflow: visible;
      }

      // Solo versione desktop
      @include respond-to('s-992') {
        font-size: 90%;
        top: 100%;
        position: absolute;
        z-index: 999;
        border-radius: 3px;
        text-align: left;
        min-width: 18rem;
        width: auto;
      }
    }

    &--secondLevel {
      background: map-get($map: $color-map, $key: secondary);

      @include respond-to('s-992') {
        left: 0;
      }
    }

    // secondo livello
    &--thirdLevel {
      background: map-get($map: $color-map, $key: secondary);

      @include respond-to('s-992') {
        left: auto;
        right: 100%;
        top:0
      }
      
    }
  
  }

  &__item {
    border-top: 1px solid darken(map-get($map: $color-map, $key: secondary), 8%);
    // text-transform: uppercase;
    background: transparent;
    position: relative;
    margin: 0;
    white-space: nowrap;

    // Stile primo livello
    #{$navigation-root}__wrap--firstLevel > & {
      
      // in desktop e mobile primo livello i bordi non ci sono
      border: 0;

      @include respond-to('s-992') {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        text-transform: uppercase;
      }

    }

    // il primo item ha anche il border top
    &:first-child {
      border-top: 0;

      @include respond-to('s-992') {
        // in desktop primo livello, primo figlio i bordi non ci sono
        #{$navigation-root}__wrap--firstLevel > & {
          border: 0;
        }
      }

    }

  }

  // Ancore
  &__anchor {
    display: block;
    text-align: left;
    padding: 2rem;

    svg {
      width: 21px;
      height: 21px;
    }

    &:link,
    &:visited {
      
      @at-root .header--highlight & {
        color: map-get($map: $color-map, $key: primary);
      }

      @at-root .header--plain & {
        color: map-get($map: $color-map, $key: primary);
      }
    }

    @include respond-to('s-992') {
      padding: 1rem;
    }

    #{$navigation-root}__wrap--firstLevel > .navigation__item > & {
      color: white;
      
      @include respond-to('s-992') {
        display: flex;
        align-items: center;
        padding: .5rem;

        @at-root .header--alpha & {
          color: map-get($map: $color-map, $key: primary);
        }

        @at-root .header--charlie & {
          color: map-get($map: $color-map, $key: primary);
        }

        @at-root .header--delta & {
          color: map-get($map: $color-map, $key: primary);
        }

        @at-root .header__topbar & {
          color: map-get($map: $color-map, $key: primary);
        }

      }
      
      @include respond-to('s-992') {
        padding: 0;
      }
    }

    // Secondo livello
    #{$navigation-root}__wrap--secondLevel > .navigation__item > & {
      color: white;

      &:hover,
      &:active {
        background: darken(map-get($map: $color-map, $key: secondary), 5%);
      }
    }

    // Secondo livello
    #{$navigation-root}__wrap--thirdLevel > .navigation__item > & {
      color: white;

      &:hover,
      &:active {
        background: darken(map-get($map: $color-map, $key: secondary), 7%);
      }
    }

    // variante con freccia
    &--arrow {
      position: relative;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to('s-992') {
        //justify-content: center;
      }
      
    }
  }

  // Solo su desktop i sottomenu vengono gestiti con l'hover.
  @include respond-to('s-992') {
    .js-openSubMenu:hover + .navigation__wrap,
    .js-openSubMenu + .navigation__wrap:hover {
      opacity: 1;
      height: auto;
      overflow: visible;
    }
  }

  &__close {
    position: fixed;
    z-index: 9999;
    color: white;
    right: 66px;
    transform: rotate(45deg);
    width: 40px!important;
    height: 40px!important;
    top: 10px;
    display: block;


    @include respond-to('s-992') {
      display: none;
    }
  }

  // hamburger
  &__ham {
    position: absolute;
    display: block;
    left: 1.5rem;
    line-height: normal;
    cursor: pointer;
    top: 2.9rem;

    @include respond-to('s-768') {
      top: 5.9rem;
    }

    &-bar {
      display: block;
      height: 2px;
      border-radius: 1px;
      background-color: map-get($map: $color-map, $key: white);
      width: 2.2rem;

      &:not(:first-child) {
        margin: 0.4rem auto 0;
      }

      &:nth-child(2) {
        width: 1.7rem;
        margin-left: 0;
      }

      @at-root .header--alpha & {
        background-color: map-get($map: $color-map, $key: terziary);
      }
      
    }

    @include respond-to('s-992') {
      display: none;
    }
  }


}