.listing {
  $dk: 's-992';

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //overflow: hidden;
  max-width: 100%;

  @include respond-to($dk) {
    flex-direction: row;
    align-items: stretch;
    overflow: visible;
  }

  // h2 {display: none}

  &__filterAction {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    cursor: pointer;

    svg {margin-right: 5px;}

    @include respond-to($dk) {
      display: none;
    }
  }

  &__sidebar{
    background: map-get($color-map, white);
    position: fixed;
    left: -100%;
    top: 0;
    height: 100vh;
    overflow: auto;
    z-index: 9;
    transition: all 300ms;
    box-shadow: none;

    &--show {
      left: 0;
      box-shadow: 0 10px 10px -5px;
    }

    @include respond-to($dk) {
      overflow: visible;
      height: auto;
      position: static;
      flex: 0 0 15%;
      margin-right: 30px;
      display: flex; // cosi il box all'interno è alto 100%
    }
  }

  &__products {
    flex: 1;
    transition: filter 300ms;
    
    &--blurred {
      filter: blur(2px);

      @include respond-to($dk) {
        filter: none;
      }

      &:after {
        content: '';
        z-index: 1;
        position: fixed;
        top:65px;
        bottom:0;
        right:0;
        left: 0;

        @include respond-to($dk) {
          display: none;
        }
      }
    }
  }


}