.button {
  $root: &;

  $size-small: 22px;
  $size-normal: 42px;
  $size-base: 50px;
  $size-large: 57px;

  // Defaults
  display: inline-block;
  zoom:1;
  font-family: 'Avenir-Medium';
  font-size: 1.8rem;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 0;
  transition: opacity 200ms;
  height: $size-base;
  padding: 0 40px;

  @include respond-to('s-768') {
    margin-top: 0;
  }

  &::-moz-focus-inner { // moz fix
    padding: 0;
    border: 0
  }

  &-icontext {
    display: inline-flex;
    justify-content: center;
    align-items: center
  }

  &-interactive {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: $size-base;
    height: $size-base;
    border: 1px solid rgba(map-get($color-map, black), .6);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 999999;
    padding: 0;

    & > svg {
      width: 25px;
      height: 25px;
    }
  }

  // Status
  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &:active {
    border-color: map-get($color-map, black);
  }

  &[disabled] {
    border: 1px solid transparent;
    background-image: none;
    filter: alpha(opacity=40);
    opacity: .3;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none
  }

  &-shadow {
    box-shadow: 0 7px 20px 0 rgba(map-get($color-map, primary), 0.2);

    &-small {
      box-shadow: 0 1px 5px 0 rgba(map-get($color-map, blue-grey), 0.5);
    }
  }

  &-transparent {
    background: transparent;
    border: 0;
  }

  &-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0;
    width: $size-base;
    height: $size-base;

    &-square {
      border-radius: 2px;
    }

    &#{$root}-small {
      width: $size-small;
      height: $size-small;
    }

    &#{$root}-normal {
      width: $size-normal;
      height: $size-normal;
    }

    &#{$root}-large {
      width: $size-large;
      height: $size-large;
    }
  }

  &-outline {
    border: 1px solid;

    &-light,
    &-light:link,
    &-light:visited {
      @extend .button-outline;
      border-color: map-get($color-map, white);
      color: map-get($color-map, white);
    }

    &-dark,
    &-dark:link,
    &-dark:visited {
      @extend .button-outline;
      border-color: map-get($color-map, terziary);
      color: map-get($color-map, terziary);
    }
  }

  &-primary,
  &-secondary,
  &-terziary,
  &-success,
  &-error,
  &-warning,
  &-paypal,
  &-facebook,
  &-twitter,
  &-pinterest {
    color: map-get($color-map, white)!important;
  }

  // Styles
  &-primary   {background: map-get($color-map, primary)}
  &-secondary {background: map-get($color-map, secondary)}
  &-terziary  {background: map-get($color-map, terziary)}
  
  &-success   {background: map-get($color-map, apple)}
  &-error     {background: map-get($color-map, carnation)}
  &-warning   {background: map-get($color-map, buttercup)}
  &-paypal    {background: map-get($color-map, paypal)}
  &-facebook  {background: map-get($color-map, facebook)}
  &-twitter   {background: map-get($color-map, twitter)}
  &-pinterest {background: map-get($color-map, pinterest)}

  // Size
  &-small   {
    height: $size-small;
    font-size: 1.2rem;
    padding: 0 2px;
  }
  
  &-large {
    height: $size-large;
    font-size: 2.2rem;
  }

  // others
  &-block {
    width: 100%;
  }

}











