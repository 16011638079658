.footer {
  $root: &;

  // adv
  &__adv {
    padding: 3rem 0;
  }

  &__advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > svg {
      margin-bottom: 5rem;
    }
  }

  // parte alta
  &__top {
    padding: 8rem 0 0;
    background: map-get($color-map, secondary);
    color: map-get($color-map, white);
    position: relative;

    a:link,
    a:visited {
      color: map-get($color-map, white);
    }
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    z-index: 1;
  }

  &__title {
    @extend %size-micro;
    margin-bottom: 0.8rem;
    line-height: 1;
  }

  &__list {
    @extend %size-micro;
    list-style: none;
    line-height: 1.4;
    margin-bottom: 15px;

    & > li {
      margin: 0 0 0.2rem 0
    }

    &--spaced {
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 0 1.8rem;
        flex-basis: 50%;
      }
    }

  }

  &__table {
    width: 100%;
    @extend %size-micro;

    td {
      line-height: 1.7;
    }
  }

  // parte bassa
  &__bottom {
    @extend %size-micro;
    padding: 1.5rem 0;
    background: rgba(map-get($color-map, primary), 80%);
    color: map-get($color-map, white);

    a:link,
    a:visited {
      color: map-get($color-map, white);
    }

    & .tippy-popper {
      a:link,
      a:visited {
        color: map-get($color-map, primary);
      }
    }
  }

}